import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Paper,
  Typography,
  Container,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { GetPassCode, ResetPassword } from "../../api";

const Forgot = ({ setForgot }) => {
  const history = useHistory();
  const [form, setForm] = useState({
    PassCode: "",
    NewPassword: "",
    ConfirmPassword: "",
  });
  const [step, setStep] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  function validateForm() {
    if (form.NewPassword !== form.ConfirmPassword) {
      setError("The passwords do not match");
      return false;
    }
    return true;
  }

  const GetRestPassCode = (e) => {
    e.preventDefault();
    setLoading(true);
    GetPassCode(form.PhoneNumber)
      .then((Response) => Response.json())
      .then((result) => {
        setLoading(false);
        if (result.Error === false) {
          toast.success(`${result.Message}`);
          setStep(1);
        } else {
          toast.error(`${result.Message}`);
          setError(result.Message);
        }
      });
  };

  const handleSubmit = () => {
    if (!validateForm()) return;
    setLoading(true);
    ResetPassword(form)
      .then((Response) => Response.json())
      .then((result) => {
        setLoading(false);
        if (result.Error === false) {
          toast.success(`${result.Message}`);
          history.push("/login");
        } else {
          toast.error(`${result.Message}`);
          setError(result.Message);
        }
      });
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const commonStyles = {
    formContainer: {
      paddingTop: "100px",
      paddingBottom: "100px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh",
      backgroundColor: "#2c3d4e", // Updated to dark blue
    },
    paper: {
      padding: "40px 30px",
      borderRadius: "12px",
      boxShadow: "0px 4px 25px rgba(0, 0, 0, 0.1)",
      backgroundColor: "#fff",
      width: "100%",
      maxWidth: "400px",
    },
    heading: {
      fontSize: "24px",
      fontWeight: "bold",
      textAlign: "center",
      marginBottom: "25px",
      color: "#2c3d4e", // Updated to dark blue
    },
    button: {
      marginTop: "20px",
      padding: "12px",
      backgroundColor: "#44dc94", // Updated to green
      color: "#2c3d4e", // Updated text color to dark blue for contrast
      borderRadius: "8px",
      cursor: "pointer",
      textAlign: "center",
      width: "100%",
      textTransform: "uppercase",
      fontWeight: "500",
      letterSpacing: "0.5px",
      boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.1)",
      transition: "all 0.3s ease-in-out",
    },
    buttonSpacing: {
      marginTop: "15px",
    },
    buttonSecondary: {
      backgroundColor: "#fff",
      color: "#44dc94", // Updated to green
      border: "1px solid #44dc94", // Updated to green
    },
    errorText: {
      color: "#ff6b6b", // A shade of red that works well with the new color scheme
      fontSize: "14px",
      marginTop: "10px",
      textAlign: "center",
    },
    successIcon: {
      fontSize: "60px",
      color: "#44dc94", // Updated to green
      marginBottom: "20px",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
      gap: "20px",
      marginTop: "25px",
    },
    input: {
      width: "100%",
      padding: "12px 15px",
      margin: "10px 0",
      borderRadius: "8px",
      border: "1px solid #ccc",
      fontSize: "14px",
      outline: "none",
      transition: "border-color 0.3s ease-in-out",
    },
    inputFocus: {
      borderColor: "#44dc94", // Updated to green
    },
  };

  return (
    <div style={commonStyles.formContainer}>
      <Container component="main" maxWidth="xs">
        <Paper style={commonStyles.paper}>
          {step === 0 && (
            <div>
              <Typography style={commonStyles.heading}>
                Forgot your password? Enter your phone number
              </Typography>
              <form onSubmit={GetRestPassCode}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <input
                      type="number"
                      name="PhoneNumber"
                      value={form.PhoneNumber || ""}
                      onChange={handleChange}
                      style={commonStyles.input}
                      placeholder="Phone Number"
                      required
                    />
                  </Grid>
                  {error && <div style={commonStyles.errorText}>{error}</div>}
                  <Grid item xs={12}>
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <Button type="submit" style={commonStyles.button}>
                        Submit
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      onClick={() => setForgot(false)}
                      style={{
                        ...commonStyles.button,
                        ...commonStyles.buttonSecondary,
                      }}
                    >
                      Back to Login
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          )}

          {step === 1 && (
            <div>
              <div style={{ textAlign: "center" }}>
                <i
                  className="fas fa-check-circle"
                  style={commonStyles.successIcon}
                ></i>
              </div>
              <Typography
                style={{
                  fontSize: "18px",
                  textAlign: "center",
                  marginBottom: "20px",
                  color: "#333",
                }}
              >
                A passcode has been sent to your phone. Please reset your
                password below.
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <input
                    type="text"
                    name="PassCode"
                    value={form.PassCode || ""}
                    onChange={handleChange}
                    style={commonStyles.input}
                    placeholder="Enter Pass Code"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <input
                    type="password"
                    name="NewPassword"
                    value={form.NewPassword || ""}
                    onChange={handleChange}
                    style={commonStyles.input}
                    placeholder="New Password"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <input
                    type="password"
                    name="ConfirmPassword"
                    value={form.ConfirmPassword || ""}
                    onChange={handleChange}
                    style={commonStyles.input}
                    placeholder="Confirm Password"
                    required
                  />
                </Grid>
                {error && <div style={commonStyles.errorText}>{error}</div>}
              </Grid>
              {loading ? (
                <CircularProgress />
              ) : (
                <div style={commonStyles.buttonContainer}>
                  <Button
                    onClick={() => setStep(0)}
                    variant="outlined"
                    size="small"
                    style={{
                      ...commonStyles.button,
                      ...commonStyles.buttonSecondary,
                    }}
                  >
                    Resend Code
                  </Button>
                  <Button
                    size="small"
                    onClick={handleSubmit}
                    style={commonStyles.button}
                  >
                    Save Changes
                  </Button>
                </div>
              )}
            </div>
          )}

          {step === 2 && (
            <div>
              <div style={{ textAlign: "center" }}>
                <i
                  className="fas fa-check-circle"
                  style={commonStyles.successIcon}
                ></i>
              </div>
              <Typography style={{ fontSize: "18px", textAlign: "center" }}>
                Please check your internet connection and try again.
              </Typography>
              <div style={commonStyles.buttonContainer}>
                <Button
                  onClick={() => history.push("/")}
                  variant="outlined"
                  style={commonStyles.button}
                >
                  Back to Home
                </Button>
                <Button
                  onClick={() => setStep(0)}
                  variant="contained"
                  style={commonStyles.button}
                >
                  Resend Link
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </Container>
    </div>
  );
};

export default Forgot;
