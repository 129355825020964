import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import {
  Avatar,
  Box,
  Typography,
  makeStyles,
  Menu,
  MenuItem,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import uimage from "../../assets/sidebar/images/users/user_1.png";
import bgprofile from "../../assets/sidebar/images/backgrounds/admin.jpg";
import { Hidden, IconButton } from "@mui/material";
import {
  Close,
  Download,
  LogoutOutlined,
  Loyalty,
  PaymentsRounded,
} from "@mui/icons-material";
import { AppContext } from "../../AppContext";
import { Message, Settings } from "@material-ui/icons";
import {
  IconDatabase,
  IconManualGearbox,
  IconSportBillard,
} from "@tabler/icons";
import { encryptSession } from "../../api";
import { VendorProductCheck } from "../common/Functions";

const useStyles = makeStyles((theme) => ({
  piclarge: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  profileBg: {
    backgroundImage: `url(${bgprofile})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  profileTextBg: {
    cursor: "pointer",
    backgroundColor: "rgba(0, 0, 0,0.5)",
    padding: "5px 10px",
    display: "flex",
  },
  menuitem: {
    width: 200,
    border: "1px solid #ccc",
    margin: "4px",
    borderRadius: "10px",
    minWidth: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "8px",
  },
}));

const type = +encryptSession.getItem("type");

const Profile = (props) => {
  const { context, VendorImage } = useContext(AppContext);
  const vendorName = context.VendorName;
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const userProfile = {
    avatar: `https://api.crato.co.ke/api/File/VendorImage?ImageID=${VendorImage}`,
    jobTitle: "Senior Developer",
    name:
      vendorName?.length <= 15
        ? vendorName?.toUpperCase()
        : vendorName?.slice(0, 15).toUpperCase() + "...",
    href: "/",
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = async () => {
    const cacheKeys = await caches.keys();
    cacheKeys.forEach(async (key) => {
      await caches.delete(key);
    });
  };

  const handleRedirect = (link) => {
    history.push(`${link}`);
    handleClose();
  };

  return (
    <Box className={classes.profileBg} color="white">
      <div style={{ float: "right" }}>
        <Hidden mdDown>
          <IconButton
            onClick={props.toggleSidebar}
            edge="end"
            sx={{ border: "2px solid white", color: "white" }}
            // color="red"
            aria-label="menu"
          >
            <Close />
          </IconButton>
        </Hidden>
      </div>
      <Box p={3}>
        <Avatar
          component={NavLink}
          className={classes.piclarge}
          src={userProfile.avatar}
          to={userProfile.href}
        />
      </Box>
      <Typography
        variant="h6"
        className={classes.profileTextBg}
        onClick={handleClick}
      >
        {userProfile.name}
        <Box flexGrow={1} />
        <ExpandMoreIcon fontSize="small" />
      </Typography>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {VendorProductCheck(2) && (
          <MenuItem
            onClick={() => handleRedirect("/loyalty/dashboard")}
            className={classes.menuitem}
          >
            <Loyalty />
            LOYALTY CARD
          </MenuItem>
        )}
        {VendorProductCheck(3) && (
          <MenuItem
            onClick={() => handleRedirect("/fuelcard/dashboard")}
            className={classes.menuitem}
          >
            <Loyalty />
            FUEL CARD
          </MenuItem>
        )}
        {(type === 4 || type === 5) && (
          <MenuItem
            onClick={() => handleRedirect("/crm/dashboard")}
            className={classes.menuitem}
          >
            <Message />
            CRM
          </MenuItem>
        )}
        <MenuItem
          onClick={() => handleRedirect("/location/location")}
          className={classes.menuitem}
        >
          <Settings />
          SET UP
        </MenuItem>

        <MenuItem
          onClick={() => handleRedirect("/settings/billing")}
          className={classes.menuitem}
        >
          <PaymentsRounded />
          BILLING
        </MenuItem>

        <MenuItem
          onClick={() => handleRedirect("/settings/auditlogs")}
          className={classes.menuitem}
        >
          <IconDatabase /> AUDIT LOGS
        </MenuItem>

        <MenuItem
          className={classes.menuitem}
          onClick={() => handleRedirect("/settings/download")}
        >
          <Download /> DOWNLOADS
        </MenuItem>

        <MenuItem
          onClick={() => handleRedirect("/settings/manual")}
          className={classes.menuitem}
        >
          <IconManualGearbox /> USER GUIDE
        </MenuItem>

        <MenuItem onClick={() => logout()} className={classes.menuitem}>
          <LogoutOutlined /> CLEAR CACHE
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default Profile;
