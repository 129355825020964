import React, { useContext } from "react";
import Navitems from "./NavItems";
import { Divider, Drawer, Hidden, makeStyles } from "@material-ui/core";
import { Box, List } from "@mui/material";
import { SidebarWidth } from "../../assets/sidebar/jss/Theme-variable";
import Menuitems from "./data";
import Profile from "./Profile";
import Distribution from "./Distribution";
import { encryptSession } from "../../api";
import { VendorProductCheck } from "../common/Functions";
import { AppContext } from "../../AppContext";

const useStyles = makeStyles((theme) => ({
  mobileSidebar: {
    width: SidebarWidth,
  },
  desktopSidebar: {
    width: SidebarWidth,
    top: 0,
    borderRight: "0px",
    height: "calc(100%)",
    boxShadow: "1px 0px 20px rgba(0, 0, 0, 0.08)",
  },
}));

const Sidebar = (props) => {
  const classes = useStyles();
  const { locd } = useContext(AppContext);

  let list2 = locd?.VendorLocationCratoProductArray;

  const productIDs = Array.isArray(list2)
    ? list2.map((item) => item.CratoProductID)
    : [];

  const SidebarContent = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      sx={{
        backgroundImage: "linear-gradient(#2c3d4e, #44dc94);",
        marginRight: "2px",
        overflow: "hidden",
        height: "100%",
      }}
    >
      <Profile toggleSidebar={props.toggleSidebar} />
      <Divider />
      <Box p={2}>
        <List>
          {Menuitems?.filter((m) =>
            productIDs?.some((id) => m.type.includes(id))
          ).map((m, index) => (
            <Navitems
              href={m.href}
              key={index} // Key for each Navitem
              title={m.title}
              icon={m.icon}
            />
          ))}
        </List>
      </Box>

      {props.children}

      <Box flexGrow={1} />

      {VendorProductCheck(6) && <Distribution />}
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={props.isMobileSidebarOpen}
          onClose={props.onSidebarClose}
          classes={{ paper: classes.mobileSidebar }}
          variant="temporary"
        >
          {SidebarContent}
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopSidebar }}
          open={props.isSidebarOpen}
          variant="persistent"
        >
          {SidebarContent}
        </Drawer>
      </Hidden>
    </>
  );
};

export default Sidebar;
