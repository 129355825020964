import React, { useEffect, useState } from "react";
import "./styles.css";
import { Menu, MenuItem } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const Tabs = (props) => {
  const [active, setActive] = useState(localStorage.getItem("tab"));
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuIdx, setMenuIdx] = useState(null);
  const { Headers, handleTabs } = props;

  // const handleClick = (child, idx, option) => {
  //   handleTabs(child, option);
  //   localStorage.setItem("tab", idx);
  //   setActive(idx);
  //   handleMenuClose();
  // };

  const currentUrl = window.location.href;
  const lastPart = currentUrl.split("/").pop();

  useEffect(() => {
    const newIndex = Headers[0]?.children?.findIndex(
      (item) => item.url.split("/").pop() === lastPart
    );
    setActive(newIndex);
  }, [Headers, lastPart]);

  // const handleMenuOpen = (event, idx) => {
  //   setAnchorEl(event.currentTarget);
  //   setMenuIdx(idx);
  // };

  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  //   setMenuIdx(null);
  // };

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (anchorEl && !anchorEl.contains(event.target)) {
  //       handleMenuClose();
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [anchorEl]);

  return (
    <div className="wrapper">
      <div className="tabs_wrap">
        {Headers.map((tab, tabIndex) => (
          <ul key={tabIndex}>
            {tab.children.map((child, idx) => (
              <li
                key={idx}
                style={{
                  background: active === idx ? "#44dc94" : "",
                  color: active === idx ? "#fff" : "",
                }}
                // onMouseEnter={(event) => handleMenuOpen(event, idx)}
                onClick={(event) => handleTabs(child, idx)}
                // onMouseLeave={handleMenuClose}
              >
                {child.icon} {child.id}
                
              </li>
            ))}
          </ul>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
