import React from "react";
import styled, { keyframes } from "styled-components";

// Define the animation keyframes
const thumbsUpAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;

// Define the styled SVG component
const ThumbsUpIcon = styled.svg`
  border-radius: 50%;
  fill: #28a745;
  width: 150px;
  height: 100px;
  animation: ${thumbsUpAnimation} 1s infinite; /* Apply the animation */
`;

export default ThumbsUpIcon;
