import React, { useContext, useEffect, useState } from "react";
import { accountingFormat } from "../../common/Functions";
import { CircularProgress, Tooltip } from "@mui/material";
import { Print } from "@material-ui/icons";
import moment from "moment";
import { GetVendorInvoice } from "../../../api";
import ThumbsUpIcon from "./ThumpsUpp";
import axios from "axios";
import { AppContext } from "../../../AppContext";

export default function Billing() {
  const { context, setContext } = useContext(AppContext);
  const [paymentData, setPaymentData] = useState([]);
  const [values, setValues] = useState(null);
  const [pay, setPay] = useState(false);
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const { invoice } = GetVendorInvoice(refresh);

  const InvoiceNO = paymentData?.filter((d) => d.StatusID === 1)[0]?.InvoiceNO;

  useEffect(() => {
    setPaymentData(invoice);
  }, [invoice]);

  const handleFormSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://api.crato.co.ke/api/Account/RequestSTKPush",
        {
          phoneNumber: values.PhoneNumber,
          invoiceNO: InvoiceNO,
        },
        {
          headers: {
            accept: "*/*",
            "Content-Type": "application/json-patch+json",
          },
        }
      );

      setResult(response.data); // Store the response in the state
      CheckPaymentStatus();
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      // Handle the error
    }
  };

  const CheckPaymentStatus = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `https://api.crato.co.ke/api/Account/CheckStkPush?CheckoutRequestID=${result.CheckoutRequestID}`,
        {
          phoneNumber: "0702628741",
          invoiceNO: "INV2308-0001",
        },
        {
          headers: {
            accept: "*/*",
            "Content-Type": "application/json-patch+json",
          },
        }
      );

      console.log("Response:", response.data);
      setResult(response.data); // Store the response in the state
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      // Handle the error
    }
  };

  const ConfirmPayment = async () => {
    if (result?.TransID && result?.TransID.length > 9) {
      setLoading(true);
      try {
        const response = await axios.post(
          `https://api.crato.co.ke/api/Account/CompleteServicePayment`,
          {
            transID: result?.TransID,
            invoiceNO: InvoiceNO,
          },
          {
            headers: {
              accept: "*/*",
              "Content-Type": "application/json-patch+json",
            },
          }
        );

        console.log("Response:", response.data);
        setResult(response.data); // Store the response in the state
        setValues({
          ...values,
          error: response?.data?.Error,
          errorMessage: response.data.Message,
        });
        setRefresh(!refresh);
        setLoading(false);
        setContext({ ...context, refresh: !context?.refresh });
        if (response?.data?.Error === false) {
          window.location.reload(); // Corrected line to trigger a page reload
        }
      } catch (error) {
        console.error("Error:", error);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (result?.CheckoutRequestID) {
      CheckPaymentStatus();
    } else {
      if (result?.TransID) {
        ConfirmPayment();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  const Balance = paymentData?.filter((d) => d.StatusID === 1)[0]
    ?.InvoiceAmount;

  const showThumbsUp = !Balance || Balance === 0;

  const getStatusBar = (statusID) => {
    const statusText = statusID === 2 ? "PAID" : "PENDING";
    const statusColor = statusID === 2 ? "#28a745" : "#dc3545";
    return (
      <span style={{ display: "flex", alignItems: "center" }}>
        <span
          style={{
            display: "inline-block",
            padding: "5px 10px",
            borderRadius: "4px",
            color: "#fff",
            fontWeight: "bold",
            backgroundColor: statusColor,
          }}
        >
          {statusText}
        </span>
        <span
          style={{
            width: "1px",
            backgroundColor: "transparent",
            display: "inline-block",
          }}
        >
          &nbsp;
        </span>
        {statusID === 2 && (
          <Tooltip title="Print Receipt">
            <span
              style={{
                cursor: "pointer",
                display: "inline-block",
                padding: "5px 10px",
                borderRadius: "4px",
                color: "#fff",
                background: "grey",
                fontWeight: "bold",
              }}
            >
              <Print />
            </span>
          </Tooltip>
        )}
      </span>
    );
  };

  return (
    <div>
      <div style={{ flex: 1 }}>
        {paymentData?.length === 0 ? (
          <p>No invoices available</p>
        ) : (
          <div style={{ overflowX: "auto" }}>
            <table
              style={{
                borderCollapse: "collapse",
                border: "1px solid #ccc",
                width: "100%",
                overflow: "auto",
                fontFamily: "Arial, sans-serif",
              }}
            >
              <thead>
                <tr
                  style={{
                    backgroundColor: "#f2f2f2",
                    borderBottom: "2px solid #ddd",
                    textTransform: "uppercase",
                  }}
                >
                  <th style={{ padding: "12px", textAlign: "left" }}>
                    Invoice NO
                  </th>
                  <th style={{ padding: "12px", textAlign: "left" }}>
                    INVOICE DATE
                  </th>
                  <th style={{ padding: "12px", textAlign: "left" }}>
                    DUE DATE
                  </th>
                  <th style={{ padding: "12px", textAlign: "left" }}>
                    STATIONS
                  </th>
                  <th style={{ padding: "12px", textAlign: "right" }}>
                    SERVICE FEE
                  </th>
                  <th style={{ padding: "12px", textAlign: "left" }}>
                    TOTAL AMOUNT
                  </th>
                  <th style={{ padding: "12px", textAlign: "left" }}>STatus</th>
                </tr>
              </thead>
              <tbody>
                {paymentData?.slice(0, 2)?.map((invoice) => (
                  <tr
                    key={invoice.InvoiceID}
                    style={{ borderBottom: "1px solid #ddd" }}
                  >
                    <td style={{ padding: "12px", textAlign: "left" }}>
                      {invoice.InvoiceNO}
                    </td>
                    <td style={{ padding: "12px", textAlign: "left" }}>
                      {moment(invoice.DateAdded).format("DD/MM/YYYY")}
                    </td>
                    <td style={{ padding: "12px", textAlign: "left" }}>
                      {moment(invoice.DueDate).format("DD/MM/YYYY")}
                    </td>
                    <td style={{ padding: "12px", textAlign: "right" }}>
                      {invoice.TotalLocation}
                    </td>
                    <td style={{ padding: "12px", textAlign: "right" }}>
                      {accountingFormat(invoice.ServiceFee)}
                    </td>
                    <td style={{ padding: "12px", textAlign: "right" }}>
                      {accountingFormat(invoice.InvoiceAmount)}
                    </td>
                    <td style={{ padding: "12px", textAlign: "left" }}>
                      {getStatusBar(invoice.StatusID)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div
        style={{
          backgroundColor: "#f5f5f5",
          padding: "20px",
          borderRadius: "5px",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
          // maxWidth: "300px",
        }}
      >
        {showThumbsUp ? (
          <div
            style={{
              // display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "150px",
              borderBottom: "4px solid #28a745",
            }}
          >
            <ThumbsUpIcon
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 356 356">
                <rect width="356" height="356" fill="none" />
                <path
                  d="M32,104H80a0,0,0,0,1,0,0V208a0,0,0,0,1,0,0H32a8,8,0,0,1-8-8V112A8,8,0,0,1,32,104Z"
                  fill="none"
                  stroke="green"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="24"
                />
                <path
                  d="M80,104l40-80a32,32,0,0,1,32,32V80h61.9a15.9,15.9,0,0,1,15.8,18l-12,96a16,16,0,0,1-15.8,14H80"
                  fill="none"
                  stroke="green"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="24"
                />
              </svg>
            </ThumbsUpIcon>
            <p style={{ fontSize: "28px", marginBottom: "20px" }}>
              <h6 style={{ fontWeight: "bold", fontSize: "28px" }}>
                {" "}
                Your account is up to date
              </h6>
            </p>
          </div>
        ) : (
          <div>
            <h2>{paymentData.month}</h2>
            <p style={{ fontSize: "18px", marginBottom: "20px" }}>
              Payment Balance:{" "}
              <h6 style={{ fontWeight: "bold" }}>
                {accountingFormat(Balance)}
              </h6>
            </p>
            {pay !== "stk" && (
              <button
                style={{
                  backgroundColor: !pay ? "#007bff" : "grey",
                  margin: "10px",
                  color: "white",
                  border: "none",
                  padding: "10px 15px",
                  borderRadius: "5px",
                  minWidth: "100px",
                  cursor: "pointer",
                  transition: "background-color 0.2s",
                }}
                onClick={() => {
                  !pay ? setPay("stk") : setPay(null);
                }}
              >
                {pay ? "Cancel" : "INITIATE PAYMENT"}
              </button>
            )}
            {pay !== "code" && (
              <button
                style={{
                  backgroundColor: !pay ? "#007bff" : "grey",
                  margin: "10px",
                  color: "white",
                  border: "none",
                  padding: "10px 15px",
                  borderRadius: "5px",
                  minWidth: "100px",
                  cursor: "pointer",
                  transition: "background-color 0.2s",
                }}
                onClick={() => {
                  !pay ? setPay("code") : setPay(null);
                }}
              >
                {pay ? "Cancel" : "USE MPESA CODE"}
              </button>
            )}
            <div style={{ fontWeight: "bold", marginTop: "20px" }}>
              CALL ADMIN +254702628741
            </div>
          </div>
        )}
        {pay && Balance && (
          <div style={{ marginTop: "10px" }}>
            <h5>
              {pay === "stk"
                ? "Enter the phone number that is paying"
                : "Enter the Mpesa Transaction Code"}
            </h5>
            {pay === "stk" ? (
              <input
                aria-label="phone number"
                onChange={(e) =>
                  setValues({ ...values, PhoneNumber: e.target.value })
                }
              />
            ) : (
              <input
                aria-label="phone number"
                onChange={(e) =>
                  setResult({ ...result, TransID: e.target.value })
                }
              />
            )}
            <div style={{ color: "red" }}>
              {result?.ResultDesc ||
                result?.ResponseDescription ||
                values?.errorMessage}{" "}
              !!!
            </div>
            {loading ? (
              <div>
                <CircularProgress />
              </div>
            ) : (
              <div style={{ marginTop: "5px" }}>
                {pay === "stk" && (
                  <button onClick={() => handleFormSubmit()}>
                    GET STK PUSH
                  </button>
                )}
                {loading ? (
                  <CircularProgress />
                ) : (
                  <button
                    style={{ marginLeft: "3px" }}
                    onClick={() => ConfirmPayment()}
                  >
                    COMPLETE
                  </button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
