import React from "react";
import { Box, Button } from "@material-ui/core";

const Distribution = () => {
  return (
    <Box p={2} m={2} bgcolor="background.dark">
      <Box display="flex" justifyContent="center" mt={2}>
        <Button
          onClick={() => {
            window.location.href = "/distribution/dashboard";
          }}
          style={{ backgroundColor: "#2c3d4e", color: "white" }}
          component="a"
          disableElevation
          variant="contained"
        >
          Distribution
        </Button>
      </Box>
    </Box>
  );
};

export default Distribution;
