import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Box from "@mui/system/Box";
import { IconExclamationCircle } from "@tabler/icons";
import Billing from "../Settings/Billing";
import SuspenseCounter from "./CountDown";
import "./button.css";

const PaymentModal = ({ DueDate }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white", // Adjust the background color
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // Add a subtle shadow
    p: 4,
  };

  return (
    <Box>
      <Button
        variant="contained"
        onClick={handleOpen}
        startIcon={<IconExclamationCircle />}
        className="pulsate"
      >
        Invoice
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <SuspenseCounter />

          <Billing />
          <Button
            variant="contained"
            onClick={handleClose}
            sx={{ marginTop: 2, float: "right" }} // Add some spacing
          >
            Close
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default PaymentModal;
