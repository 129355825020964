import { useContext, useState, useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "../AppContext";

const EXPIRATION_TIME = 172800000; // 2 days in milliseconds
const RETRY_COUNT = 3; // Number of retries for fetching
const lastFetchTimeMap = new Map();

export const useApiRes = (api, options) => {
  const { refresh } = options;
  const location = useLocation();
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [network, setNetwork] = useState(navigator.onLine);
  const [visible, setVisible] = useState(true);
  const { context, setContext } = useContext(AppContext);
  const prevRefreshRef = useRef(refresh);
  const prevRefresh = prevRefreshRef.current;
  const FETCH_INTERVAL = prevRefresh !== context.refresh ? 0 : 30000;

  const manageCache = useCallback(async () => {
    try {
      const cacheNames = await caches.keys();
      const now = Date.now();

      for (const cacheName of cacheNames) {
        const cache = await caches.open(cacheName);
        const keys = await cache.keys();

        for (const request of keys) {
          const url = new URL(request.url);
          const timestamp = url.searchParams.get("timestamp");

          if (timestamp && now - parseInt(timestamp, 10) > EXPIRATION_TIME) {
            await cache.delete(request);
          }
        }
      }
    } catch (err) {
      console.error("Error managing cache: ", err);
    }
  }, []);

  const addDataIntoCache = useCallback(
    async (api, response) => {
      try {
        const data = new Response(JSON.stringify(response));
        if ("caches" in window) {
          const cache = await caches.open(api);
          await manageCache();
          const timestampedUrl = new URL(api);
          timestampedUrl.searchParams.set("timestamp", Date.now().toString());

          // Clear old cache entries for this API
          const keys = await cache.keys();
          for (const request of keys) {
            if (request.url.startsWith(api)) {
              await cache.delete(request);
            }
          }

          await cache.put(timestampedUrl.toString(), data);
        }
      } catch (err) {
        console.error("Error adding data to cache: ", err);
      }
    },
    [manageCache]
  );

  const getCachedData = useCallback(async (api) => {
    try {
      if (typeof caches === "undefined") return null;
      const cacheNames = await caches.keys();

      for (const cacheName of cacheNames) {
        const cache = await caches.open(cacheName);
        const keys = await cache.keys();

        for (const request of keys) {
          if (request.url.startsWith(api)) {
            const cachedResponse = await cache.match(request);
            if (cachedResponse && cachedResponse.ok) {
              return cachedResponse.json();
            }
          }
        }
      }
      return null;
    } catch (err) {
      console.error("Error getting cached data: ", err);
      return null;
    }
  }, []);

  const fetchData = useCallback(
    async (abortController, retries = RETRY_COUNT) => {
      setContext((prevContext) => ({ ...prevContext, fetching: true })); // Set fetching to true when fetching starts
      try {
        const response = await fetch(api, { signal: abortController.signal });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        if (!api.includes("undefined") && !data.errors) {
          await addDataIntoCache(api, data);
          setData(data); // Update the state with the new data after caching
          lastFetchTimeMap.set(api, Date.now()); // Update the last fetch time
          setError(null);
        } else {
          throw new Error("API returned undefined or errors");
        }
      } catch (error) {
        if (error.name !== "AbortError" && retries > 0) {
          console.log(`Retrying... Attempts left: ${retries}`);
          await fetchData(abortController, retries - 1); // Retry fetching data
        } else {
          const cachedData = await getCachedData(api);
          if (cachedData) {
            setData(cachedData);
            setError("Fetch failed, showing cached data.");
          } else {
            setError("Fetch failed and no cached data available.");
          }
        }
      } finally {
        setContext((prevContext) => ({ ...prevContext, fetching: false })); // Set fetching to false when fetching ends
      }
    },
    [api, addDataIntoCache, getCachedData, setContext]
  );

  useEffect(() => {
    const controller = new AbortController();

    const updateData = async () => {
      const cachedData = await getCachedData(api);
      if (cachedData) {
        setData(cachedData); // Update the state with cached data
        setContext((prevContext) => ({ ...prevContext, fetching: false }));
      } else {
        setContext((prevContext) => ({ ...prevContext, fetching: true }));
      }

      const lastFetchTime = lastFetchTimeMap.get(api);
      const now = Date.now();
      if (network) {
        if (!lastFetchTime || now - lastFetchTime >= FETCH_INTERVAL) {
          await fetchData(controller); // Fetch new data in the background
        } else {
          setContext((prevContext) => ({ ...prevContext, fetching: false }));
        }
      } else {
        if (!cachedData) {
          setError("No internet connection and no cached data available.");
          setContext((prevContext) => ({ ...prevContext, fetching: false }));
        }
      }
    };

    updateData();

    return () => {
      controller.abort();
    };
  }, [api, location, refresh, network, visible, getCachedData, fetchData, setContext]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setVisible(document.visibilityState === "visible");
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const handleOnlineStatus = () => {
      setNetwork(navigator.onLine);
    };
    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);
    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
    };
  }, []);

  return { data, error, loading: context.fetching };
};
