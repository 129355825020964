import { Settings, TimeToLeaveOutlined } from "@material-ui/icons";
import {
  Dns,
  House,
  People,
  PermMedia,
  Phonelink,
  ProductionQuantityLimits,
  Support,
} from "@mui/icons-material";
import { IconBoxModel } from "@tabler/icons";
import { encryptSession } from ".";

const VendorID = encryptSession.getItem("ci");

export const NavBar = [
  {
    id: "sales",
    children: [
      { id: "saledash", icon: <Dns />, url: "sales/saledash" },
      { id: "report", icon: <PermMedia />, url: "sales/history" },
      { id: "customer", icon: <People />, url: "sales/customer" },
      // { id: "invoice", icon: <SettingsEthernet />, url: "sales/invoice" },
    ],
  },
  {
    id: "inventory",
    children: [
      { id: "report", icon: <Settings />, url: "inventory/report" },
      // {
      //   id: "action",
      //   icon: <TimeToLeaveOutlined />,
      //   url: "inventory/action",
      // },
      {
        id: VendorID === 30 ? "offloading" : "purchase",
        icon: <ProductionQuantityLimits />,
        url: VendorID === 30 ? "inventory/offloading" : "inventory/purchase",
      },
      { id: "products", icon: <IconBoxModel />, url: "inventory/inventory" },
      { id: "supplier", icon: <Support />, url: "inventory/supplier" },
    ],
  },
  {
    id: "finance",
    children: [
      { id: "statements", icon: <Settings />, url: "finance/statement" },
      {
        id: "expense",
        icon: <TimeToLeaveOutlined />,
        url: "finance/expense",
      },
      { id: "payroll", icon: <Phonelink />, url: "finance/payroll" },
      { id: "Tax", icon: <House />, url: "finance/tax" },
      // { id: "finance", icon: <Phonelink />, url: "finance/transfer" },
      // { id: "supplier", icon: <Phonelink />, url: "inventory/supplier" },
    ],
  },
  {
    id: "shift",
    children: [
      { id: "shift", icon: <Settings />, url: "shift/clock" },

      { id: "DailySummary", icon: <Phonelink />, url: "shift/DailySummary" },
      // { id: "transfer", icon: <Phonelink />, url: "shift/transfer" },
      // { id: "supplier", icon: <Phonelink />, url: "inventory/supplier" },
    ],
  },
];
