import { useContext } from "react";
import { encryptSession } from "../../api";
import DefaultImage from "../../assets/no-image-icon-6.png";
import { AppContext } from "../../AppContext";

export function accountingFormat(num) {
  if (num === null || num === undefined) {
    return "";
  }
  if (num < 0) {
    return (
      "(" +
      Math.abs(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) +
      ")"
    );
  } else {
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
}

export default function getDefaultImageAsBase64(type = "png") {
  return fetch(DefaultImage)
    .then((response) => response.arrayBuffer())
    .then((buffer) => {
      const binary = [].slice.call(new Uint8Array(buffer));
      const base64String = btoa(
        binary.map((charCode) => String.fromCharCode(charCode)).join("")
      );
      const prefix =
        type === "png" ? "data:image/png;base64," : "data:image/jpeg;base64,";
      const base64 = `${prefix}${base64String}`;
      return { DefaultImage, base64 };
    })
    .catch((error) => {
      console.error("Error loading default image:", error);
      return { DefaultImage, base64: "" };
    });
}

export function validateState(state, name) {
  // Check if the field exists and is not null
  if (state[name] == null) {
    console.log(`Field '${name}' is null.`);
    return false;
  }

  // Specific check for text fields
  if (typeof state[name] === "string") {
    if (state[name].trim() === "") {
      console.log(`Text field '${name}' is empty.`);
      return false; // Invalid if text field is empty
    }
  }

  // Specific check for numeric fields
  if (typeof state[name] === "number") {
    if (isNaN(state[name])) {
      console.log(`Numeric field '${name}' is NaN.`);
      return false; // Invalid if number field is NaN (not a possible case unless set explicitly)
    }
  }

  // Valid field
  console.log(`Field '${name}' is valid.`);
  return true;
}

export function DateTimeFormat(dateAdded, type) {
  // Assuming dateAdded is in Nairobi time zone, which is 'Africa/Nairobi'
  const nairobiTime = new Date(dateAdded).toLocaleString("en-US", {
    timeZone: "Africa/Nairobi",
  });
  const localDate = new Date(nairobiTime);

  // Check if date is valid
  if (isNaN(localDate.getTime())) {
    return "Invalid Date";
  }

  // Define options based on the type parameter and include timeZone to be dynamic
  let options;
  switch (type) {
    case "dateonly":
      options = { year: "numeric", month: "2-digit", day: "2-digit" };
      break;
    case "timeonly":
      options = {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      };
      break;
    case "monthday":
      options = { month: "2-digit", day: "2-digit" };
      break;
    case "datetime":
    default:
      options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      };
      break;
  }

  // Format the date according to the options
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  options.timeZone = userTimeZone; // Set options to use user's local time zone
  const formattedDateTime = localDate.toLocaleString("en-US", options);
  return formattedDateTime;
}

export function DefaultContextDateRange(timeRange) {
  const monthFirstDay = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  );
  const todayStart = new Date(new Date().setHours(0, 0, 0, 0)); // Start of today
  const now = new Date(); // Current time

  let startTime, endTime;
  switch (timeRange) {
    case "time":
      startTime = new Date(now.valueOf() - 1000 * 60 * 60 * 24); // Last 24 hours
      endTime = now;
      break;
    case "day":
      startTime = todayStart;
      endTime = now;
      break;
    case "month":
      startTime = monthFirstDay;
      endTime = now;
      break;
    case "biannual":
      startTime = new Date(now.setMonth(now.getMonth() - 6)); // Last 6 months
      endTime = new Date(); // Reset to current time after setMonth
      break;
    case "annual":
      startTime = new Date(now.setMonth(now.getMonth() - 12)); // Last 12 months
      endTime = new Date(); // Reset to current time after setMonth
      break;
    default:
      startTime = new Date(now.valueOf() - 1000 * 60 * 60 * 168); // Past week (168 hours)
      endTime = now;
      break;
  }

  return [startTime, endTime];
}

export function VendorProductCheck(ProductID) {
  const { locd } = useContext(AppContext);
  const products = locd?.VendorLocationCratoProductArray;
  // Check if the ProductID exists in the products array
  const exists = products?.some(
    (product) => product.CratoProductID === ProductID
  );

  return exists;
}
