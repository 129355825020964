import React, { useContext } from "react";
import Billing from "./components/Settings/Billing";
import { AppContext } from "./AppContext";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export default function NotPaid() {
  const { context } = useContext(AppContext);
  const history = useHistory();
  const containerStyle = {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh", // Set minHeight for full viewport height
    fontFamily: "Arial, sans-serif",
  };

  const titleStyle = {
    fontWeight: "bold",
    fontSize: "3rem", // Adjust font size for larger screens
    color: "red",
    marginBottom: "1rem", // Adjust margin for smaller screens
  };

  const subTitleStyle = {
    fontWeight: "bold",
    fontSize: "2.5rem", // Adjust font size for larger screens
    marginBottom: "1rem", // Adjust margin for smaller screens
  };

  const logout = async () => {
    const cacheKeys = await caches.keys();
    cacheKeys.forEach(async (key) => {
      await caches.delete(key);
    });
    sessionStorage.clear();
    // localStorage.clear();
    history.push("/");
    window.location.reload();
  };

  return (
    <div style={containerStyle}>
      <div style={titleStyle}>NOT PAID !!</div>
      <div style={subTitleStyle}>
        {context?.VendorName + " "} ACCOUNT IS SUSPENDED
      </div>
      {/* <div style={infoStyle}>INVOICE AMOUNT: 8000</div> */}
      <Billing />
      <Button variant="outlined" fullWidth onClick={() => logout()}>
        LOGOUT
      </Button>
    </div>
  );
}
