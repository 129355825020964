import "./assets/css/style.css";
import "./assets/css/bootstrap.css";
//background images
import homepage from "./assets/img/hero-img.png";
import Background from "./assets/img/bg_hero_1.svg";
import Typical from "react-typical";

export default function Home() {
  return (
    <div>
      <div
        className="page-hero-section bg-image hero-home-1"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <div class="hero-caption pt-5">
          <div class="container h-100">
            <div class="row align-items-center h-100">
              <div class="col-lg-6 wow fadeInUp">
                <div class="badge mb-2">
                  <h6 className="first_name" style={{ color: "red" }}>
                    <span class="icon mr-1">
                      <span class="mai-globe"></span>
                    </span>
                    We help to {""}
                    <Typical
                      loop={Infinity}
                      wrapper="b"
                      steps={[
                        "reduce your business losses",
                        1000,
                        "promote your business",
                        1000,
                        "do monthly business audit",
                        1000,
                        "achieve your business goals",
                        1000,
                      ]}
                    ></Typical>
                  </h6>
                </div>
                <h1 class="mb-4">
                  NO 1, Fuel Stations Solution in Africa today
                </h1>
                <p class="mb-4">
                  Use crato to automate your fuel station operations <br />
                  and minimize your chances of getting loses.
                </p>
                <a href="/login" class="btn btn-primary rounded-pill">
                  Get Started Now
                </a>
                <a href="/login" class="btn btn-info rounded-pill">
                  Learn more
                </a>
              </div>
              <div class="col-lg-6 d-none d-lg-block wow zoomIn">
                <div class="img-place mobile-preview shadow floating-animate">
                  <img src={homepage} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
