import React, { useEffect, useState } from "react";
import { TextField, Grid, FormControl, Autocomplete } from "@mui/material";
import { isValidPhoneNumber } from "libphonenumber-js";
import { countryCodes } from "./CountryCodes"; // Ensure this has correct structure
import { validateState } from "../../Functions";
import axios from "axios";

const PhoneNumberInput = ({ onChange, defultValues }) => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        const response = await axios.get("https://api.ipgeolocation.io/ipgeo", {
          params: {
            apiKey: "e43ab96aeb96433c8506f23f00c1f4cc",
          },
        });
        const country = {
          dialCode: `${response.data.calling_code}`,
          code: response.data.country_code2,
        };
        setSelectedCountry(country);
      } catch (error) {
        console.error("Error fetching the country code:", error);
      }
    };
    if (defultValues?.dialCode) {
      setSelectedCountry(defultValues?.dialCode);
      setPhoneNumber(defultValues?.PhoneNumber)
    } else {
      fetchCountryCode();
    }
  }, []);

  const handleCountryChange = (event, newValue) => {
    setSelectedCountry(newValue);
    checkValidity(newValue?.dialCode + phoneNumber); // Use newValue which is the full country object
  };

  const handlePhoneNumberChange = (event) => {
    const inputNumber = event.target.value;
    setPhoneNumber(inputNumber);
  };

  useEffect(() => {
    if (phoneNumber.length > 0) {
      checkValidity(selectedCountry?.dialCode + phoneNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber, selectedCountry?.dialCode]);

  const checkValidity = (fullPhoneNumber) => {
    const valid = isValidPhoneNumber(fullPhoneNumber);
    setError(!valid);
    onChange({ ...selectedCountry, phoneNumber });
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={6}>
        <FormControl fullWidth error={error}>
          <Autocomplete
            size="small"
            fullWidth
            id="country-code-autocomplete"
            options={countryCodes}
            getOptionLabel={(option) => `${option.dialCode} (${option.code})`} // Display text
            value={selectedCountry}
            onChange={handleCountryChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Country Code"
                variant="outlined"
                error={error}
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          size="small"
          error={
            error || !validateState({ PhoneNumber: phoneNumber }, "PhoneNumber")
          }
          helperText={error ? "Invalid phone number" : ""}
          label="Phone Number"
          variant="outlined"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
        />
      </Grid>
    </Grid>
  );
};

export default PhoneNumberInput;
