import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../AppContext";

const SuspenseCounter = () => {
  const { context } = useContext(AppContext);
  const dueDate = new Date(context?.DueDate);
  const currentDate = new Date();
  const timeDifference = dueDate - currentDate;
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
  const [daysLeft, setDaysLeft] = useState(daysDifference); // Number of days left
  const [hoursLeft, setHoursLeft] = useState(0);
  const [minutesLeft, setMinutesLeft] = useState(0);
  const [secondsLeft, setSecondsLeft] = useState(0);

  useEffect(() => {
    const countdownTimer = setInterval(() => {
      const now = new Date();
      const targetDate = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + daysLeft,
        0,
        0,
        0
      );
      const timeLeft = targetDate - now;

      if (timeLeft <= 0) {
        clearInterval(countdownTimer);
        // You can add additional logic for what happens when the system is suspended
      } else {
        const hours = Math.floor(timeLeft / (1000 * 60 * 60));
        const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

        setHoursLeft(hours);
        setMinutesLeft(minutes);
        setSecondsLeft(seconds);
      }
    }, 1000);

    return () => clearInterval(countdownTimer);
  }, [daysLeft]);

  const isUrgent =
    daysLeft === 0 && hoursLeft === 0 && minutesLeft === 0 && secondsLeft <= 59;

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <h1>System Suspension Countdown</h1>
      <p style={{ fontSize: "24px" }}>
        {daysLeft?.toFixed(0)} days, {hoursLeft} hours, {minutesLeft} minutes,{" "}
        {secondsLeft} seconds
      </p>

      <p style={{ color: "red", fontSize: "18px", fontWeight: "bold" }}>
        URGENT: Please take action to prevent system suspension!
      </p>
    </div>
  );
};

export default SuspenseCounter;
