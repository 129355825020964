import React, { useState, useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Paper,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import { SendWhatsAppReport } from "../../api";
import { AppContext } from "../../AppContext";
import PhoneNumberInput from "../common/Inputs/PhoneNumber/PhoneNumber";
import { DateTimeFormat } from "../common/Functions";

const SendEmailDialog = ({ setReport, report }) => {
  const handlePhoneNumber = (value) => {
    setReport({
      ...report,
      PhoneNumber: value?.dialCode?.replace("+", "") + value?.phoneNumber,
      CountryCode: value?.dialCode?.replace("+", ""),
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
        <PhoneNumberInput onChange={handlePhoneNumber} />
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <TextField
          label="Whatsapp caption"
          value={report?.body}
          onChange={(e) => setReport({ ...report, body: e.target.value })}
          fullWidth
          multiline
          rows={4}
          margin="normal"
        />
      </Grid>
    </Grid>
  );
};

const WhatsAppSender = ({ setOpen, open, pdfBlob }) => {
  const [reports, setReports] = useState({ body: "" });
  const { context } = useContext(AppContext);

  const handleClose = () => setOpen(false);

  const onSuccess = () => {
    setOpen(false);
  };

  const { loading, handleFormSubmit: handleUpload } = SendWhatsAppReport(
    {
      PhoneNumber: reports?.PhoneNumber,
      pdfBase64: pdfBlob,
      FileName: context.reporttitle,
      ReportName: "",
      VendorLocationName: context?.VendorLocationName,
      TradingName: context.TradingName + "-" + reports?.body,
      DateRange:
        DateTimeFormat(context?.DateRange[0], "dateonly") +
        " to " +
        DateTimeFormat(context?.DateRange[1], "dateonly"),
      Body: reports?.body,
      CompanyPhone: "254-702628741",
    },
    [onSuccess]
  );

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Send Email</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Paper style={{ padding: 10 }}>
              <Typography variant="h6">PDF Preview</Typography>
              {pdfBlob ? (
                <iframe
                  src={`data:application/pdf;base64,${pdfBlob}`}
                  type="application/pdf"
                  width="100%"
                  height="600px"
                  title="PDF Viewer"
                />
              ) : (
                <Typography>... loading please wait</Typography>
              )}
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <SendEmailDialog setReport={setReports} report={reports} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        {loading ? (
          <CircularProgress />
        ) : (
          <Button onClick={handleUpload}>Share Report</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default WhatsAppSender;
